<template>
         <div class="loginPage col-12 pl-0 pr-0">
            <div id="loginShow" v-if="present == 'login' & localParentPresent =='login'">
            <h2 class="text-center">Login</h2>
            <p v-if="showBrowserAlert" style="color:#1e467f!important;">
                Please use the newest version of <span style="color:#3ac47d !important;font-weight:600;"> Google Chrome/Firefox </span> to use all features.
            </p>
            <hr/>
            <div class="row" style="margin: 25px 0px!important;">
<div class="col-xl-6 col-lg-12 pt-3 p-2">
                 <!-- <facebook-login class="fb-signin-button"
                    appId="526833059617006"
                    @login="onLogin"
                    @logout="onLogout"
                    loginLabel="Sign in with Facebook"
                    @sdk-loaded="sdkLoaded">
                    </facebook-login> -->
                    <vue-apple-login
  mode="center-align"
  type="sign in"
  color="black"
  :border="true"
  :radius="5"
  width="100%"
  height="40px"
  logoSize="medium"
  :logoPosition="0"
  :labelPosition="0"
  className="vue-apple-login"
  :onSuccess="onAppleSuccess"
  :onFailure="onAppleFailure"
></vue-apple-login>
             </div>
            <div class="col-xl-6 col-lg-12 pt-3 p-2">
             
         <GoogleLogin :params="params"  :onSuccess="onSuccess" class="btn gmailBtn">
                <img src="../assets/images/google.png" class="googleIcon"> 
                Sign in with Google</GoogleLogin> 
        <!-- :renderParams="renderParams" -->
            <!-- <button class="btn gmailBtn" type="button" v-if="!isSignIn"
        :disabled="!isInit"  @click="handleClickSignIn" >
              <img src="../assets/images/google.png" class="googleIcon"> Sign in with Gmail
            </button> -->
             </div>
</div>
            
            <h4 v-if="error!=null" class="text-danger"><strong> {{error}}</strong></h4>
            <form class="mb-4">
                <div class="form-group text-left ml-1">
                    <label for="username">User Name / Email</label>
                    <input type="text" v-model="form.email_id" name="email" class="form-control" />
                    <!-- :class="{ 'is-invalid': !form.email_id }" -->
                    <!-- <div v-show="!form.email_id" class="invalid-feedback">Username is required</div> -->
                </div>
                <div class="form-group text-left ml-1">
                    <label htmlFor="password">Password</label>
                    <input type="password" v-model="form.password" name="password" class="form-control" />
                    <!-- <div v-show="!form.password" class="invalid-feedback">Password is required</div> -->
                </div>
                <div class="col-12 mb-4">
                    <button type="button" @click="submit()" class="loginBtn btn btn-success" block :disabled="!validData" variant="primary">Login</button>
                    <!-- <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" /> -->
                    
                </div>
                 
            </form>
            
                    <b-row class="mt-2">
                    <b-col cols="12"  class="text-center"><b-link style="font-weight: 600;" class="text-info" @click="present = 'forgetPassword'"  left>Forgot your password?</b-link></b-col>
                    </b-row>
                    <b-row class="mt-2">
                    <b-col cols="12" class="text-center mt-2" ><span style="color: black;font-weight: 600;">Don't have an account? </span><b-link style="font-weight: 600;" class="text-info" @click="present = 'register'" right>Create an account</b-link></b-col>
                    </b-row>
            </div>
            <app-Register v-if="present=='register' & localParentPresent =='login'"/>
             <app-ForgotPassword v-if="present=='forgetPassword' & localParentPresent =='login'"/>
            <app-ResetPassword v-if="present=='login' & localParentPresent =='resetPassword' "/>
        </div>
</template>
<!-- <script src="https://accounts.google.com/gsi/client" async defer></script> -->
<!-- https://github.com/guruahn/vue-google-oauth2/tree/master -->

<script>
import { mapActions,mapGetters } from 'vuex'
//import  {getGoogleUrl}  from '../utils/getGoogleUrl';
import GoogleLogin from 'vue-google-login';
import facebookLogin from 'facebook-login-vuejs';
import ForgotPassword from '@/components/ForgotPassword.vue'
import Register from '../components/Register.vue'
import ResetPassword from '../components/ResetPassword'
//import LocalStorageService from '../store/LocalStorageService';
export default {
    props:{
        parentPresent:{
            default:'login',
            required:false
        }
    },
    components:{
            GoogleLogin,
            facebookLogin,
            'app-Register':Register,
            'app-ForgotPassword':ForgotPassword,
            'app-ResetPassword':ResetPassword,
            

        },
    data () {
        return {
            from :'/',
            present:'login',
            localParentPresent:this.parentPresent,
            showBrowserAlert:false,
            signedIn:false,
            form: {
                email_id:'',
                password:'',
            },
           
            isInit: false,
            isSignIn: false,
            error: null,
            params: {
                   client_id: "662951235152-ua8fih2usk2g9h2bfh81i8d2dvstt0vl.apps.googleusercontent.com" //nextest311@g, A1b...
                   //client_id: nextest311@g, A1b... "736567673817-m7fljp74t1n6a7r189lfoev7fimhbust.apps.googleusercontent.com"

                },
                // only needed if you want to render the button with tshe google ui
                renderParams: {
                    width: 250,
                    height: 50,
                    longtitle: true
                },
                userAnswer:{
                first_name:'',
                last_name:'',
                email_id:'',
                url:''
            },
            fb: {
                cu:'',
                fV:'',
                isConnected:false
            }
        }
    },
    methods:{
        ...mapActions({
            signIn: 'auth/login',
            loginSocialMedia: 'auth/loginSocialMedia'
            
        }),
        ...mapGetters({
            getError: 'auth/getError',
            authDetail: 'auth/authenticated'
        }),
       
        callingRecheckUser(){
            this.$parent.$parent.recheckUser()
        },
        onAppleSuccess(data) {
                console.log(data);
                var vm = this
                this.signedIn = true;
                const tempData=data.userData;
                var user = {
                        first_name: tempData.givenName,
                        email_id: tempData.email,
                        last_name:tempData.familyName,
                        active_ind:'T',
                        apple_user_id:tempData.sub
                    };
                    if(user.first_name== null & user.apple_user_id==null){
                    return this.$alert(
                                "Login Failed.",
                                "Failed",
                                "error",
                                {
                                    confirmButtonText: "Try Again!"
                                });
                     }
                     this.loginSocialMedia(user).then(()=>{
                    const details = this.authDetail()
                    
                    console.log(details) 
                    
                    this.$ls.set('tokenVal',details.token)
                    this.$ls.set('uniqueKey', details.uniqueKey)
                    this.$ls.set('user',details.user)
                    this.$ls.set('currentQuestion','dashboard')
                    vm.$browserStore.setLocal('tokenVal', details.token)
                    vm.$browserStore.setLocal('uniqueKey', details.uniqueKey)
                    vm.$browserStore.setLocal('backUser',JSON.stringify(details.user))
                    vm.$ls.set('currentQuestion','dashboard')
                    this.$parent.$parent.recheckUser()
                    });
            },
            onAppleSuccessLogout(){
                this.signedIn = false;
            },
            onAppleFailure(error) {
                console.log(error);
                return this.$alert(
                                "Login Failed.",
                                "Failed",
                                "error",
                                {
                                    confirmButtonText: "Try Again!"
                                });
            },
        submit(){
            ////debugger
            // try{
                this.$browserStore.clearLocal()
                var vm = this
            this.signIn(this.form).then(function() {
                ////debugger;
                ////console.log(vm.getError())
                if(vm.getError()== '') 
                {
                    //this.$router.push('/Home')
                    //vm.$root.$emit('bv::hide::modal', 'login-Modal')
                    vm.$browserStore.setLocal('tokenVal', vm.$ls.get('tokenVal'))
                    vm.$browserStore.setLocal('backUser',JSON.stringify(vm.$ls.get('user')))
                    vm.$ls.set('currentQuestion','dashboard')
                    //vm.$ls.remove('currentQuestion')
                    vm.$parent.$parent.recheckUser()
                    //resetUser()
                    //LocalStorageService.setCurrentQuestion('')
                }
                else{
                    vm.error = vm.getError()
                    //console.log(vm.error)
                }
            })
        //     .catch(error => {
        //       //console.log(error);
        //   });
        //     }
        //     catch(ex){
        //         //console.log(ex)
        //     }
            
        },
        onSuccess(googleUser) {
            ////console.log(googleUser);
 
            // This only gets the user information: id, name, imageUrl and email
            //console.log(googleUser.getBasicProfile());
            const tempData=googleUser.getBasicProfile();
            ////console.log(tempData)
            //console.log(tempData.getEmail())
            var vm = this
            var user = {
                        first_name: tempData.getGivenName(),
                        email_id: tempData.getEmail(),
                        last_name:tempData.getFamilyName(),
                        active_ind:'T'
                    };
            
            if(user.first_name== null){
                return this.$alert(
                            "Login Failed.",
                            "Failed",
                            "error",
                            {
                                confirmButtonText: "Try Again!"
                            });
            }
            //console.log(user);
            this.loginSocialMedia(user).then(()=>{
            const details = this.authDetail()
            
            console.log(details) 
            
             this.$ls.set('tokenVal',details.token)
             this.$ls.set('uniqueKey', details.uniqueKey)
             this.$ls.set('user',details.user)
            this.$ls.set('currentQuestion','dashboard')
            vm.$browserStore.setLocal('tokenVal', details.token)
            vm.$browserStore.setLocal('uniqueKey', details.uniqueKey)
            vm.$browserStore.setLocal('backUser',JSON.stringify(details.user))
            vm.$ls.set('currentQuestion','dashboard')
            this.$parent.$parent.recheckUser()//resetUser()
            //LocalStorageService.setCurrentQuestion('')
            
            //////console.log(this.currentUser)
            //this.$root.$emit('bv::hide::modal', 'login-Modal')
            });
            //this.googleLogin(googleUser.getBasicProfile());
            
        },
        getUserData(){
            var vm=this;
            window.FB.api('/me','GET',{fields: 'name,email'},
                userInformation =>{
                    //console.warn("data api", userInformation);
                    this.fb.cu = userInformation.email;
                    this.fb.fV = userInformation.name;
                    //console.log(this.fb)
                    if(this.fb.cu!=null){
                        var user = {
                        first_name: this.fb.fV,
                        email_id: this.fb.cu,
                        active_ind:'T'
                            };
                        this.loginSocialMedia(user).then(()=>{
                        const details = this.authDetail()
                        //console.log(details)
                         vm.$browserStore.setLocal('tokenVal', vm.$ls.get('tokenVal'))
                         vm.$browserStore.setLocal('uniqueKey', vm.$ls.get('uniqueKey'))
                        vm.$browserStore.setLocal('backUser',JSON.stringify(vm.$ls.get('user')))
                        this.$ls.set('currentQuestion','dashboard')
                        this.$parent.$parent.recheckUser()//resetUser()
                        //LocalStorageService.setCurrentQuestion('')
                        
                        //////console.log(this.currentUser)
                        //this.$root.$emit('bv::hide::modal', 'login-Modal')
                        });
                    }
                    
                }
            )

            
        },
        sdkLoaded(payload){
            this.fb.isConnected = payload.isConnected;
            this.fb.FB = payload.FB
            if(this.isConnected) this.getUserData();
        },
        onLogin(){
            debugger
            this.fb.isConnected = true;
            this.getUserData();
        },
        onLogout(){
            this.isConnected = false;
        }
    },
    watch:{
        form:{
            deep: true,
            handler(){
                this.error =null
            }
        },
    },
    computed: {
        validData: function(){
       return this.error==null && this.form.email_id!='' && this.form.password!='';
      }
    },
    mounted(){
        //debugger
        if(this.$ls.get('referral')!=null){
            //console.log('Inside login '+ this.$ls.get('referral'))
            this.present='register'
        }
        console.log(this.$ls.get('userSettings'));
        if(this.$ls.get('userSettings')!=null){
            
           if(this.$ls.get('userSettings')=='removeAccount') return this.$alert("To remove your account permanently you need to login first & delete it from Settings.")
        }
    },
    created() {
        if(this.$browserDetect.isSafari){
            //debugger
            this.showBrowserAlert=true
        }
        
       
    },
}
</script>
<style>
.social-auth {
  max-width: 27rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.google-auth {
  background-color: #fff;
  border-radius: 5px;
  padding: 6px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.google-auth img {
    height: 30px;
    margin-right: 10px;
}
.google-auth span {
    font-size: 18px;
    color: #1d467f;
    font-weight: 600;
}

.google-auth:hover {
  box-shadow: 0 1px 13px 0 rgb(0 0 0 / 15%);
}
/* .loginPage
{
    width: 40%;
    position: absolute;
    left: 50%;
    top: 50%; 
    transform: translate(-50%, -50%);
    border: 5px solid #D9D9D9;
    padding: 20px;
    border-radius: 10px;
    background: #f7f7f7;
} */
.googleIcon{
    width: 25px;
    padding-right: 5px;
}
.btn.gmailBtn{
    background: #fff;
    padding: 8px 0px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px;
}
.loginBtn, .fb-signin-button button
{
    width: 100%!important;
    margin: auto!important;
    height: 41px!important;
    border-radius: 5px!important;
    color: #fff!important;
    min-width: auto!important;
}
.loginBtn
{
    width: 120px!important;
}
.google-signin-button {
  color: white;
  background-color: red;
  height: 35px;
  font-size: 16px;
  border-radius: 10px;
  padding: 10px 20px 25px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.fb-signin-button {
  /* This is where you control how the button looks. Be creative! */
  border-radius: 3px;
  color: #fff;
  padding: 0px!important;
}
.abcRioButtonLightBlue {
    background-color: #fff;
    color: #fbbc05;
    margin: auto;
    
}
#google-signin-btn-3{ position: relative;
display: block;}

.fb-signin-button button{display: block;
letter-spacing: 0px;
margin: auto;
height: 50px;
padding: 0 15px 0 35px !important;
}

.fb-signin-button button img {
    position: absolute;
    top: 15px;
    left: 10px;
    width: 20px;
}
.spinner{
    display: none!important;
}
label
{
    font-weight: 600;
}
.abcRioButtonLightBlue.abcRioButton{
    height: 40px!important;
width: 100% !important;
border-radius: 5px!important;
}
.abcRioButtonIcon
{
    padding-top: 12px!important;
}
.abcRioButtonContents
{
    line-height: 40px!important;
    margin-left: 0px!important;
    margin-right: 20px!important;
}
.fb-signin-button button img {
    top: 11px!important;
}
.ml-xl-5.loginBg {
    min-width: 590px!important;
}
.googleIcon{
    width: 25px;
    left: 10px;
    position: absolute;
}
.btn.gmailBtn{
    background: #fff;
    padding: 8px 0px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px!important;
    color: #757575!important;
}
</style>